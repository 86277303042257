import httpRequest from 'superagent';
import PageLoader from './page-loader';


class ServerRenderedPageLoader extends PageLoader {

	constructor({root = document.body, timeout = 10000, format = 'json', query = {json: 1}} = {}) {
		super({root: root});
		this.timeout = timeout;
		this.format = format;
		this.query = query;
	}


	requestPage(request) {
		if (!('url' in request)) {
			return Promise.reject(new Error('invalid request format'));
		}

		if (request.navigationType === 'current') {
			const response = {content: this.root};
			return Promise.resolve(response);
		}

		const req = httpRequest
			.get(request.url)
			.timeout(this.timeout)
			.set('X-Requested-With', 'XMLHttpRequest');
		if (this.format === 'json') {
			req.set('Accept', 'application/json');
		}
		const query = Object.assign({}, this.query);
		if ('refererUrl' in request) {
			query.refererUrl = request.refererUrl;
		}
		req.query(query);
		return new Promise((resolve) => {
			req.end((error, response) => {
				if (!error && response.ok) {
					resolve(response.body);
				} else if (error && error.status === 404) {
					resolve(error.response.body);
				} else {
					if (!error.response) {
						error.response = response;
					}
					throw error;
				}
			});
		});
	}

}


export default ServerRenderedPageLoader;
